import { z } from "zod";

export const teamSchema = z.object({
  name: z.string(),
  description: z.string(),
  list_order: z.coerce.number(),
  image: z.instanceof(File).optional()
});

export const functionSchema = z.object({
  team_id: z.coerce.number(),
  title: z.string(),
  coordinator: z.string().optional(),
  coordinator_amount: z.coerce.number(),
  crew: z.string().optional(),
  crew_amount: z.coerce.number()
});

const motivationSchema = z.object({
  teamId: z.coerce.number().optional(),
  functionId: z.coerce.number().optional(),
  type: z.string().optional(),
  motivation: z.string().min(1)
});

export const sollicitationSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phone: z.string(),
  dob: z.date(),
  student: z.boolean(),
  studyTime: z.coerce.number().optional(),
  motivations: z.object({
    first: motivationSchema,
    second: motivationSchema.optional(),
    third: motivationSchema.optional()
  }),
  source: z.string(),
  eula: z.boolean().refine((a) => a === true, "De EULA moet geaccepteerd worden")
});

export type SollicitationSchema = z.infer<typeof sollicitationSchema>;
